






















import { Component, Vue } from 'vue-property-decorator';
import { CotisationDetailTable } from '@/components';
import { cotisationDetailModule } from '@/store';
import { CotisationDetail } from '@/models';

@Component({
  components: { CotisationDetailTable }
})
export default class CotisationDetailVue extends Vue {
  private goBack() {
    this.$router.back();
  }

  private cotisationDetail: CotisationDetail | null = null;

  async mounted() {
    const numeroCotisationParContrat = this.$route.params.id;
    await Promise.all([
      cotisationDetailModule.initCotisationDetail({
        numeroCotisationParContrat: numeroCotisationParContrat
      })
    ]);

    this.cotisationDetail = cotisationDetailModule.getCotisationDetail;
  }
}
